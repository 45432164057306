<script setup lang="ts">
const { activePortion, createPortion } = usePortionStore();
const { setValidation, required } = useValidation();
const modalStore = useModalStore();
const $api = useApi();

const props = defineProps({
  onConfirm: Function,
  default: () => {},
});
const isUpdatePortion = computed(() => !!activePortion);

const optionsSelectUnit = ref([]);

const portionData = reactive({
  label: '',
  description: '',
  quantity: null,
  minSizeDish: null,
  incrementSize: null,
  efficacyFactor: null,
  unitId: null,
});

watch(
  () => activePortion,
  (updatePortion) => {
    if (!updatePortion) {
      return;
    }

    const {
      label,
      description,
      quantity,
      unit,
      minSizeDish,
      incrementSize,
      efficacyFactor,
    } = updatePortion;

    portionData.label = label;
    portionData.description = description;
    portionData.quantity = quantity;
    portionData.unitId = unit.id;
    portionData.incrementSize = incrementSize;
    portionData.efficacyFactor = efficacyFactor;
    portionData.minSizeDish = minSizeDish;
  },
  { immediate: true },
);

const validation = setValidation(portionData, {
  label: { required },
  description: { required },
  quantity: { required },
  incrementSize: { required },
  efficacyFactor: { required },
  minSizeDish: { required },
  unitId: { required },
});

async function handleUpsertPortion() {
  if (isUpdatePortion.value) {
    await $api.put(`/portions/${activePortion.id}`, portionData);
  } else {
    await createPortion(portionData);
  }
  props.onConfirm();
  await modalStore.closeModal();
}

async function fetchUnits() {
  const { data: units } = await $api.post('/units/all', {
    search: '',
  });
  if (units.length) {
    optionsSelectUnit.value = units
      .filter((u) => ['G', 'ML'].includes(u.symbol))
      .map((u) => ({
        value: u.id,
        label: `${u.label} - ${u.symbol}`,
      }));
  }
}

fetchUnits();
</script>

<template>
  <BaseModal
    :title="
      $t(`modal.portionUpsert.${isUpdatePortion ? 'update' : 'create'}.title`)
    "
    :width="900"
  >
    <BaseInput
      v-model="validation.label.$model"
      label="Label"
      type="text"
      :validation="validation.label"
      required
    />
    <BaseInput
      v-model="validation.description.$model"
      label="Description"
      type="textarea"
      :validation="validation.description"
      required
    />

    <BaseSelect
      v-model="portionData.unitId"
      label="Unité"
      :options="optionsSelectUnit"
      required
    />

    <BaseInput
      v-model="validation.quantity.$model"
      label="Quantité"
      type="number"
      :validation="validation.quantity"
      required
    />

    <BaseInput
      v-model="validation.minSizeDish.$model"
      label="Taille min."
      type="number"
      :validation="validation.minSizeDish"
      required
    />

    <BaseInput
      v-model="validation.incrementSize.$model"
      label="Increment"
      type="number"
      :validation="validation.incrementSize"
      required
    />

    <BaseInput
      v-model="validation.efficacyFactor.$model"
      label="Facteur d'efficacité"
      type="number"
      :validation="validation.efficacyFactor"
      required
    />

    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton
        text="Confirmer"
        @click="handleUpsertPortion"
        :disabled="validation.$invalid"
      />
    </template>
  </BaseModal>
</template>

<style scoped></style>
